import React from "react";
import { Box } from "@mui/material";
import { TypeAnimation } from "react-type-animation";
import elevmi_hero from "../../assets/images/elevmi_hero.png";
import trace from "../../assets/images/trace.png";
import { EnterWaitingList } from "./EnterWaitingList";

const TypingAnimation = (sx) => {
  const delayBetweenStrings = 2000;
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          overflow: "visible",
          top: "0",
          left: { xs: "-15px", md: "-15px" },
          width: {
            xs: "110%",
            sm: "410px",
            md: "430px",
            lg: "530px",
            xl: "650px",
          },
          height: "100%",
          objectFit: "fill",
          objectPosition: "center",
          zIndex: "-1",
          transform: { xs: "scale(1)", sm: "scale(1.1)" },
        }}
      >
        <img
          src={trace}
          alt=""
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </Box>
      <TypeAnimation
        preRenderFirstString={true}
        speed={70}
        deletionSpeed={80}
        sequence={[
          "remaining patient.",
          delayBetweenStrings,
          "managing fights.",
          delayBetweenStrings,
          "making the home safer.",
          delayBetweenStrings,
          "letting off steam.",
          delayBetweenStrings,
          "dealing with wandering.",
          delayBetweenStrings,
          "getting a quick answer.",
          delayBetweenStrings,
          "the next steps.",
          delayBetweenStrings,
          "what’s “normal.”",
          delayBetweenStrings,
          "emotional healing.",
          delayBetweenStrings,
          "doctor visit prep.",
          delayBetweenStrings,
          "managing outbursts.",
          delayBetweenStrings,
        ]}
        wrapper="span"
        cursor={false}
        repeat={Infinity}
        style={{
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      />
    </Box>
  );
};

export function Hero() {
  return (
    <Box
      sx={{
        backgroundColor: {
          xs: "rgba(106, 72, 185, 0.07)",
          sm: "rgba(211, 201, 249, 0.10)",
        },
        width: "100vw",
        overflowX: { xs: "auto", sm: "hidden" },
      }}
    >
      <Box
        sx={{
          gap: "20px",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: { xs: "start", lg: "center" },
            paddingTop: { xs: "30px", lg: "0px" },
            width: { sm: "40%", md: "45%", lg: "50%" },
            transform: "translateY(-10px)",
          }}
        >
          <img
            loading="lazy"
            alt="Elevmi app on smartphone screens"
            src={elevmi_hero}
            style={{
              width: "110%",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "normal",
            width: { xs: "100%", md: "50%" },
            padding: {
              xs: "48px 16px 0px 16px",
              sm: "65px 40px 16px 20px",
              lg: "66px 0px 55px 30px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "0", sm: "20px" },
            }}
          >
            <Box
              sx={{
                color: "#312A4E",
                fontFamily: "Open Sans, sans-serif ",
                letterSpacing: "-1px",
                fontWeight: "600",
                gap: "40px",
                fontSize: { xs: "35px", sm: "34px", lg: "40px", xl: "54px" },
                lineHeight: {
                  xs: "42px",
                  sm: "44px",
                  lg: "50px",
                  xl: "76px",
                },
                maxWidth: { xs: "auto", sm: "350px", lg: "600px" },
              }}
            >
              Sometimes you just need to know about
              <br />
              <TypingAnimation
                sx={{
                  color: "#312A4E",
                  fontFamily: "Open Sans, sans-serif ",
                  letterSpacing: "-1px",
                  fontWeight: "600",
                  gap: "40px",
                  fontSize: { xs: "31px", sm: "34px", lg: "40px", xl: "54px" },
                  lineHeight: {
                    xs: "42px",
                    sm: "44px",
                    lg: "50px",
                    xl: "76px",
                  },
                  maxWidth: { xs: "auto", sm: "350px", md: "600px" },
                }}
              />
            </Box>
            <Box
              sx={{
                fontFamily: "Open Sans, sans-serif ",
                fontSize: { xs: "16px", xl: "20px" },
                lineHeight: { xs: "28px", md: "24px", lg: "28px", xl: "32px" },
                fontWeight: "400",
                paddingRight: { xs: "0px", lg: "40px", xl: "80px" },
                paddingTop: "20px",
              }}
            >
              As a caregiver of a loved one with Alzheimer's, sometimes you just
              need some support. Elevmi is a free app designed to be by your
              side throughout your caregiving journey, helping you find answers
              at a moment’s notice, enabling you to record observations about
              your loved one's behaviors, helping you prepare for doctors’
              visits, and more.
            </Box>
            <EnterWaitingList variant="big" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
