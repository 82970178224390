import React from "react";
import { Helmet } from "react-helmet";
import { Typography, Stack } from "@mui/material";
import alzheimers_info_icon from "../../../assets/images/alzheimers_info.png";
import Base from "./Base";

function Support() {
  return (
    <>
      <Helmet>
        <title>Unsubscribed from Elevmi</title>
      </Helmet>
      <Base>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          gap={3}
          sx={{ maxWidth: "368px", margin: "auto" }}
        >
          <img
            src={alzheimers_info_icon}
            alt="checkmark illustration"
            style={{ height: "144px" }}
          />
          <Stack gap={2} alignItems={"center"}>
            <Typography
              sx={{
                fontFamily: "Open Sans, sans-serif",
                fontSize: "24px",
                fontWeight: 600,
                color: "#1D1A22",
                lineHeight: "46.3px",
                letterSpacing: "-0.5px",
              }}
            >
              Unsubscribe successful
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "'Source Sans 3'",
                fontSize: "16px",
                color: "#49454E",
                lineHeight: "24px",
                letterSpacing: "0.15px",
              }}
            >
              You have been successfully removed from this subscriber list and
              will no longer receive email communications from us.
            </Typography>
          </Stack>
        </Stack>
      </Base>
    </>
  );
}

export default Support;
