import React from "react";
import { Box, Link, Typography } from "@mui/material";
import elevmi_logo from "../../assets/images/elevmi_logo.svg";
import supportIcon from "../../assets/images/support.svg";

export function NavBar() {
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "84px", sm: "103px" },
          top: "0",
          left: "0",
          zIndex: "100",
          borderBottom: "1px solid #EDE9FD",
          backgroundColor: "#FBFAFF",
          width: "100%",
          fontSize: "18px",
          color: "#6147C0",
          fontWeight: "600",
          padding: { xs: "32px 16px", sm: "0 20px", md: "31px 60px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            gap: { xs: "5px", sm: "20px" },
          }}
        >
          <Link
            href={"/"}
            sx={{
              width: { xs: "97px", sm: "121px" },
            }}
          >
            <img
              loading="lazy"
              src={elevmi_logo}
              alt="Elevmi app for caregivers logo"
              style={{
                width: "100%",
              }}
            />
          </Link>
          <Link
            underline="none"
            href="/support"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "12px",
              border: "1px solid #6A48B980",
              borderRadius: "100px",
              padding: "6px 16px",
            }}
          >
            <img src={supportIcon} alt="support" style={{ width: "20px" }} />
            <Typography
              sx={{
                fontFamily: "Source Sans 3, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0.4px",
              }}
            >
              Support
            </Typography>
          </Link>
        </Box>
      </Box>
      <Box sx={{ content: "''", height: { xs: "84px", sm: "103px" } }} />
    </>
  );
}
