import React from "react";
import { Box } from "@mui/material";
import alzheimers_info_icon from "../../assets/images/alzheimers_info.png";
import get_answers_icon from "../../assets/images/get_answers.png";
import prepare_appointments_icon from "../../assets/images/prepare_appointments.png";
import record_observations_icon from "../../assets/images/record_observations.png";

export function Card({ text, image, alt }) {
  return (
    <Box
      key={alt}
      sx={{
        display: "flex",
        flexDirection: "column",
        lineHeight: "normal",
      }}
    >
      <Box
        sx={{
          borderRadius: "24px",
          backgroundColor: "#FBFAFE",
          display: "flex",
          flexGrow: "1",
          flexDirection: "column",
          fontSize: "18px",
          color: "#000",
          fontWeight: "400",
          textAlign: "center",
          lineHeight: "28px",
          width: "100%",
          padding: "29px 24px",
          "@media (maxWidth: 991px)": {
            marginTop: "24px",
            padding: "0 20px",
          },
        }}
      >
        <Box
          sx={{
            width: { xs: "72px", md: "93px", lg: "124px", xl: "144px" },
            alignSelf: "center",
          }}
        >
          <img
            loading="lazy"
            src={image}
            alt={alt}
            style={{
              width: "100%",
              aspectRatio: "1",
              objectFit: "auto",
              objectPosition: "center",
            }}
          />
        </Box>
        <Box
          sx={{
            leadingTrim: "both",
            textEdge: "cap",
            fontFamily: "Open Sans, sans-serif",
            fontSize: { xs: "16px", sm: "18px" },
            lineHeight: "24px",
            fontWeight: "400",
            marginTop: "30px",
          }}
        >
          {text}
        </Box>
      </Box>
    </Box>
  );
}

export function CardsSection() {
  const cards = React.useMemo(() => {
    return [
      {
        text: "Record and better understand changes in your loved one’s behaviors.",
        image: record_observations_icon,
        alt: "Record observations",
      },
      {
        text: "Helping you find answers, straightforward guidance, and instant encouragement.",
        image: get_answers_icon,
        alt: "Get answers, guidance, encouragement",
      },
      {
        text: "Better prepare for doctor appointments and send reports ahead of time.",
        image: prepare_appointments_icon,
        alt: "Prepare for doctors appointments",
      },
      {
        text: "Get Alzheimer's information and learn from experiences of other caregivers.",
        image: alzheimers_info_icon,
        alt: "Get Alzheimer's information",
      },
    ];
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: {
          xs: "48px 16px",
          md: "80px 16px",
          lg: "100px 16px",
          xl: "120px 16px",
        },
        justifyContent: "stretch",
        gap: { xs: "24px", md: "80px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "0px", lg: "5px", xl: "10px" },
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            fontFamily: "Open Sans, sans-serif ",
            fontSize: { xs: "28px", sm: "30px", xl: "40px" },
            lineHeight: { xs: "40px", sm: "50px" },
            fontWeight: "600",
            paddingBottom: "10px",
          }}
        >
          You’re not alone on your caregiving journey.
        </Box>
        <Box
          sx={{
            textAlign: "center",
            fontFamily: "Open Sans, sans-serif",
            fontSize: {
              xs: "16px",
              sm: "14px",
              md: "16px",
              lg: "16px",
              xl: "18px",
            },
            lineHeight: "28px",
            fontWeight: "400",
          }}
        >
          Access tools and resources to help you along the way.
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "12px",
          justifyContent: "center",
          maxWidth: "1224px",
          gap: { xs: "24px", md: "13.5px", lg: "24px" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "stretch" },
        }}
      >
        {cards.map((card) => (
          <Card key={card.alt} {...card} />
        ))}
      </Box>
    </Box>
  );
}
