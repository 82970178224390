import React from "react";
import { Helmet } from "react-helmet";
import { Typography, Stack, Link } from "@mui/material";
import PhoneIcon from "../../../assets/images/phone.svg";
import Base from "./Base";

function Support() {
  return (
    <>
      <Helmet>
        <title>Elevmi support</title>
      </Helmet>
      <Base>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          sx={{ maxWidth: "368px", margin: "auto" }}
        >
          <Typography
            sx={{
              fontFamily: "Open Sans, sans-serif",
              fontSize: "34px",
              color: "#1D1A22",
              lineHeight: "46.3px",
              letterSpacing: "-0.5px",
            }}
          >
            Support
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Source Sans 3",
              color: "#49454E",
              lineHeight: "24px",
              letterSpacing: "0.15px",
            }}
          >
            Our team is just a phone call away. We’re available toll-free Monday
            through Friday, 8 AM - 8 PM Eastern time, except holidays.
          </Typography>
          <Link
            href={"tel:+18334687852"}
            underline="none"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
              padding: "8px",
            }}
          >
            <Stack justifyContent={"center"}>
              <img src={PhoneIcon} alt="phone number" />
            </Stack>
            <Typography
              sx={{
                fontFamily: "'Source Sans 3'",
                fontSize: "15px",
                fontWeight: "600",
                color: "#6A48B9",
                lineHeight: "26px",
                letterSpacing: "0.46px",
              }}
            >
              +1 (833) 468 7852
            </Typography>
          </Link>
        </Stack>
      </Base>
    </>
  );
}

export default Support;
