import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Fade,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  FormHelperText,
} from "@mui/material";
import { Field, useField, useFormikContext } from "formik";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ProgressIcon from "../../assets/images/progress.svg";

export function EnterWaitingList({ sx, variant = "big" }) {
  const formik = useFormikContext();
  const [fadeIn, setFadeIn] = React.useState(false);
  const animationTiming = 500;
  React.useEffect(() => {
    if (
      (formik.status === "submitted" || formik.status === "error") &&
      !fadeIn
    ) {
      const timeoutId = setTimeout(() => {
        setFadeIn(true);
      }, animationTiming);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [formik.status]);
  return (
    <Box
      sx={{
        padding: "20px 0",
        ...sx,
      }}
    >
      <Fade in={fadeIn} timeout={animationTiming} unmountOnExit>
        <Box
          sx={{
            backgroundColor: "#D9D3F7",
            color: formik.status === "error" ? "red" : "#6147C0",
            display: "flex",
            gap: "10px",
            padding: "14px 16px",
            borderRadius: "4px",
            width: "100%",
            maxWidth: "500px",
          }}
        >
          {formik.status === "submitted" && <CheckIcon />}
          <Box
            sx={{
              font: "16px Roboto, sans-serif",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: "0.15px",
            }}
          >
            {formik.status === "submitted" &&
              "Thanks for your interest in Elevmi. We will email you once the app is available for download."}
          </Box>
        </Box>
      </Fade>
      <Fade
        in={formik.status !== "submitted"}
        timeout={animationTiming}
        appear={false}
        unmountOnExit
      >
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            minWidth: variant === "small" ? "360px" : "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                color: "#000",
                leadingTrim: "both",
                textEdge: "cap",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "19.07px",
                textAlign: variant === "big" ? "left" : "center",
              }}
            >
              We’ll email you when Elevmi is available
            </Box>
            <AgreeCheckBox />
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                width: "100%",
                maxWidth: { xs: "initial", sm: "450px" },
                gap: "20px",
              }}
            >
              <EmailInput />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={
                  formik.status === "submiting" || formik.status === "submitted"
                }
                startIcon={
                  (formik.status === "submiting" ||
                    formik.status === "submitted") && (
                    <img
                      src={ProgressIcon}
                      style={{
                        height: "16px",
                        animation: "rotation 1s linear infinite",
                      }}
                      alt="loading"
                    />
                  )
                }
                sx={{
                  minWidth: "initial",
                  justifyContent: "center",
                  borderRadius: "8px",
                  textAlign: "center",
                  padding: "15px 22px",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  font: "700 15px/193% Source sans Pro, sans-serif",
                }}
              >
                Notify Me
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Box>
  );
}

function BaseInput({ field, form }) {
  const { touched, errors } = form;
  const isError = touched[field.name] && errors[field.name];

  return (
    <TextField
      placeholder="yourname@gmail.com"
      label="Email address"
      sx={{ width: "100%" }}
      FormHelperTextProps={{
        sx: {
          position: "absolute",
          bottom: "-20px",
          left: "0",
          margin: "4px 0px 0px 10px",
        },
      }}
      InputLabelProps={{
        sx: {
          marginTop: { xs: "2px", sm: "2.75px" },
        },
      }}
      InputProps={{
        ...field,
        sx: {
          height: "100%",
          minWidth: "276px",
          justifyContent: "center",
          whiteSpace: "nowrap",
          font: "400 16px Open Sans, sans-serif ",
        },
      }}
      error={!!isError}
      helperText={isError ? errors[field.name] : ""}
    />
  );
}

function EmailInput() {
  return <Field type="text" name="emailid" component={BaseInput} />;
}

function AgreeCheckBox() {
  const [, meta, helper] = useField("agree");
  const IsErrored = meta.touched && meta.error;
  return (
    <FormGroup>
      <FormControlLabel
        sx={{
          alignItems: "flex-start",
        }}
        control={
          <Checkbox
            checked={meta.value}
            sx={{
              color: IsErrored ? "#d32f2fad" : "#0000003B",
              transform: "scale(1.1)",
              margin: "5px",
            }}
            onChange={() => {
              helper.setTouched(true);
              helper.setValue(!meta.value);
            }}
          />
        }
        label={
          <Box
            onClick={(e) => e.preventDefault()}
            sx={{
              cursor: "text",
              fontSize: "16px",
              fontFamily: "Source sans Pro, sans-serif",
              letterSpacing: "0.15px",
              fontWeight: 400,
              lineHeight: "24px",
              color: "#1D1A22",
              padding: { xs: "12px 0", lg: "15px 20px 10px 0px" },
              maxWidth: "650px",
            }}
          >
            I confirm that I am 18 years of age or older, and I understand that
            the information I have provided will be used by Otsuka Precision
            Health, Inc. (“OPH”) and its contracted third parties to contact me
            with relevant information. I also consent to receive marketing
            communications via email from OPH and understand that I can opt out
            from receiving such communications at any time by calling
            1-833-468-7852 or by following the opt-out instructions within the
            communications. For more information regarding usage of this website
            and our privacy practices, see our{" "}
            <Link
              onClick={(e) => e.stopPropagation()}
              href={"/api/V1/resourceDetails/privacyPolicy/3"}
              about="Go to Elevmi Privacy Policy"
              target="_blank"
              sx={{
                color: "#6A48B9",
                fontWeight: 700,
                textDecoration: "none",
                whiteSpace: "nowrap",
              }}
            >
              Privacy Policy
            </Link>
            {" and "}
            <Link
              onClick={(e) => e.stopPropagation()}
              href={"/api/V1/resourceDetails/termsAndCondition/3"}
              about="Go to Elevmi Terms of Use"
              target="_blank"
              sx={{
                color: "#6A48B9",
                fontWeight: 700,
                textDecoration: "none",
                whiteSpace: "nowrap",
              }}
            >
              Terms of Use
            </Link>
            .
          </Box>
        }
      />
      <FormHelperText
        error
        sx={{
          margin: "0 0 0 10px",
          display: IsErrored ? "block" : "none",
        }}
      >
        {meta.error}
      </FormHelperText>
    </FormGroup>
  );
}
