import React from "react";
import { Box } from "@mui/material";
import elephants from "../../assets/images/elephants.png";
import elephants_small from "../../assets/images/elephants_small.png";
import { EnterWaitingList } from "../../components/home/EnterWaitingList";

export function ElephantSection() {
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "#FFF",
        display: "flex",
        gap: { xs: "34px", sm: "50px", md: "60px", lg: "80px" },
        width: "100%",
        flexDirection: "column",
        fontWeight: "600",
        padding: {
          xs: "48px 16px",
          md: "40px 16px",
          lg: "80px 16px",
        },
      }}
    >
      <Box
        sx={{
          alignSelf: "stretch",
          textAlign: "center",
          leadingTrim: "both",
          textEdge: "cap",
          width: "100%",
          fontFamily: "Open Sans, sans-serif",
          fontSize: { xs: "28px", md: "26px", lg: "30px", xl: "40px" },
          lineHeight: { xs: "36px", md: "50px", sm: "100px" },
        }}
      >
        The power of the herd
      </Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
          maxWidth: "1222px",
          overflow: "hidden",
          width: "100%",
          borderRadius: "6.74px",
        }}
      >
        <img
          loading="lazy"
          src={elephants_small}
          alt="Elephants caring for each other"
          style={{
            borderRadius: "inherit",
            objectFit: "auto",
            objectPosition: "center",
            width: "100%",
            overflow: "hidden",
          }}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          maxWidth: "1222px",
          overflow: "hidden",
          width: "100%",
          borderRadius: "24px",
        }}
      >
        <img
          loading="lazy"
          src={elephants}
          alt="Elephants caring for each other"
          style={{
            borderRadius: "inherit",
            objectFit: "auto",
            objectPosition: "center",
            width: "100%",
            overflow: "hidden",
          }}
        />
      </Box>
      <Box
        sx={{
          alignItems: "center",
          alignSelf: "stretch",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            maxWidth: "1136px",
            justifyContent: "space-between",
            gap: { xs: "34px", sm: "48px", md: "60px", lg: "80px" },
            padding: { sm: "0", md: "0 30px", lg: "0" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              fontFamily: "Open Sans, sans-serif",
              fontSize: { xs: "16px", md: "14px", lg: "18px" },
              lineHeight: { xs: "30px", md: "26px", lg: "32px" },
              flexGrow: "1",
              flexBasis: "auto",
            }}
          >
            Elevmi draws inspiration from elephants—symbols of strength and
            familial care. These majestic animals are known to{" "}
            <span style={{ fontWeight: 700 }}>
              come together as a herd to take care of family members in need
            </span>
            .
          </Box>
          <Box
            sx={{
              fontFamily: "Open Sans, sans-serif",
              fontSize: { xs: "16px", md: "14px", lg: "18px" },
              lineHeight: { xs: "30px", md: "26px", lg: "32px" },
              flexGrow: "1",
              flexBasis: "auto",
            }}
          >
            We understand that the selfless act of caregiving looks different
            for each individual, so wherever you are on your unique journey,{" "}
            <span style={{ fontWeight: 700 }}>let us be your elephant</span>.
          </Box>
        </Box>
      </Box>
      <EnterWaitingList
        variant="small"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          padding: { xs: "0px", md: "0 60px" },
        }}
      />
    </Box>
  );
}
